import React from "react";
import { Link } from "gatsby";

export default function Terms() {
  return (
    <div className="privacy-terms">
      <p className="effective-date">Effective Date: December 12, 2024</p>
      <p>
        Thank you for your interest in using the online services operated by
        DeltaMath ("DeltaMath", "we" or "us"). These Terms of Service ("Terms"
        or "Agreement") govern your use of DeltaMath's online services (the
        "Services"). Please continue reading to learn about the terms by which
        you may use our Services.
      </p>
      <p>
        These Terms apply to all schools, school districts, or teachers
        (collectively referred to as "Schools") as well as students, parents, or
        individuals who use the Services; (collectively along with "Schools"
        referred to as “Users” or “you”).
      </p>
      <p>
        Any material change to these policies will be announced via a banner
        message displayed on this website.
      </p>
      <p>
        By accessing or using the Services, creating an account, or by otherwise
        affirmatively stating your desire to use the Services, you signify that
        you have read, understood, and agree to be bound by this Agreement and
        to the collection and use of your information as set forth in the
        DeltaMath <Link to="/privacy-policy">Privacy Policy</Link>, otherwise
        you may not use the Services. Because our Services change relatively
        often, the terms in this Agreement and our{" "}
        <Link to="/privacy-policy">Privacy Policy</Link> may change too. Upon
        making changes, we will update the “Effective Date” found at the top of
        this page. Your continued use of the Services after any changes
        constitutes your acceptance of the new terms.
      </p>
      <h2>Regarding Student Data:</h2>
      <p>
        Our Services may be purchased by educational institutions, such as
        schools, school districts, or teachers (referred to in the collective as
        "Schools"), who utilize our Services for educational purposes. When
        DeltaMath is used by a School to provide Services, we may collect or
        access Student Data (as defined below). Such data may be provided by the
        School or directly by the student. This Student Data may, in certain
        instances, include information (e.g., student name, student email
        address) considered sensitive under applicable U.S. federal or state
        privacy laws.
      </p>
      <p>
        We treat Student Data as strictly confidential and, in general, do not
        use this data for any purpose other than enhancing and delivering our
        Services. It is solely your responsibility to obtain consent, as
        mandated by law, to use Student Data and to transfer it to DeltaMath for
        subsequent use. Our collection, processing, and sharing of Student Data
        are governed by this Agreement and applicable laws and regulations,
        including the Family Educational Rights and Privacy Act ("FERPA"), the
        Children's Online Privacy Protection Act ("COPPA"), and relevant state
        laws, including, but not limited to, the Illinois Student Online
        Personal Protection Act ("SOPPA") and New York State Ed Law 2-D.
      </p>
      <h2>DeltaMath's Service</h2>
      <p>DeltaMath is an online math practice and learning site.</p>
      <p>
        As long as you are complying with all of the terms and conditions of
        this Agreement, DeltaMath gives you permission to access and use the
        Service. The Service is available for your personal, noncommercial use.
        We are constantly changing and improving our Services. We may add or
        remove functionalities or features, and we may suspend or stop a Service
        altogether, including, for example, if you do not comply with this
        Agreement or if we are investigating suspected misconduct. We may also
        stop providing Service to you, or add or create new limits to our
        Service or restrict your access to all or part of the Service at any
        time without notice or liability.
      </p>
      <p>
        The right to access and use the Service is revoked in jurisdictions
        where it may be prohibited, if any.
      </p>
      <h2>Eligibility and Authority</h2>
      <p>
        If you create a DeltaMath account to provide the Service to students in
        a School, you represent and warrant that you are an authorized
        representative of the School with the authority to bind the School to
        this Agreement, and that you accept this Agreement on the School's
        behalf. If you contact DeltaMath to request any actions concerning an
        account, you represent and warrant that you have the necessary authority
        to make such requests either from or on behalf of the account-holder
        (e.g., a School or Parent, depending on the type of account).
      </p>
      <p>
        The U.S. Children's Online Privacy Protection Act ("COPPA") requires
        that online service providers obtain verifiable parental consent before
        collecting personal information from children under 13.
      </p>
      <p>
        For <strong>DeltaMath for Home</strong>: If you independently create a
        learner account with DeltaMath for Home, you actively affirm that you
        are 13 or over. If you are not over the age of 13, then a parent or
        guardian must create a learner account for you.
      </p>
      <p>
        Schools must obtain the necessary parental and student consent to
        collect and share Student Data with DeltaMath before utilizing our
        Services. Specifically, if your School provides the Service to children
        under the age of 13, you represent and warrant that you either have the
        authority to consent on behalf of parents or have obtained appropriate
        consent for DeltaMath to collect and use information from these students
        prior to granting them access to our Service. Use of the Services is
        strictly conditioned upon Schools securing the proper consents required
        by law for the collection and subsequent sharing of Student Data with
        DeltaMath. DeltaMath recommends that Schools provide appropriate context
        and disclosure to students and parents in order to obtain their consent,
        and assumes no responsibility for any failure by Schools to obtain the
        necessary and proper consent.
      </p>
      <h2>Your Registration Obligations</h2>
      <p>
        You agree to the following conditions in exchange for your use of the
        Service: (a) to provide true, accurate, current, and complete
        information about yourself as requested by the Service's registration
        form, referred to as "Registration Data," and (b) to promptly update
        this Registration Data as necessary to ensure it remains accurate,
        current, and complete.
      </p>

      <p>
        DeltaMath reserves the right to suspend or terminate your account and
        deny access to the Service (or any portion thereof) if you provide any
        information that is untrue, inaccurate, outdated, or incomplete, or if
        DeltaMath has reasonable grounds to suspect that such information is
        untrue, inaccurate, outdated, or incomplete.
      </p>
      <h2>General Account Information</h2>
      <p>
        DeltaMath offers a variety of account types, both free and paid, to
        subscribers with specific terms and conditions. These terms vary based
        on the account type and are outlined in quotes, sales contracts, and/or
        website descriptions. While each account has a default set of terms,
        DeltaMath can create accounts with different terms as needed. Changes to
        the terms require a written agreement between the subscriber and
        DeltaMath.
      </p>
      <h2>Account Types</h2>
      <ol>
        <li>
          <p><strong>Free teacher account:</strong> A free teacher account is for a
          single teacher user and all of the students rostered to this teacher's
          classes (i.e. students that have this teacher as the teacher of
          record). Free teacher accounts may be created through the DeltaMath
          website. See the “School Accounts and Student Data” section below for
          more information.
          </p><p>
          Students will register with a class code and complete assignments made
          by their teacher. Individual teacher licenses are intended for
          single-teacher use. We reserve the right to reach out to end-users
          and/or revoke access in cases where it appears more than one teacher
          is using an account without prior consent.</p>
        </li>

        <li>
          <p><strong>Premium teacher account (PLUS/INTEGRAL):</strong> A premium
          teacher account (either PLUS or INTEGRAL) is for a single teacher user
          and all of the students rostered to this teacher's classes (i.e.
          students that have this teacher as the teacher of record). Premium
          teacher accounts may be purchased through the DeltaMath website or by
          email. Our premium teacher accounts are sold per school year and do
          not automatically renew. The school or teacher must take action to
          renew this account. See the “School Accounts and Student Data” section
          below for more information.</p>
          <p>
          Student registration will vary, depending on the integrations the
          teacher is using in their account, and students will complete
          assignments made by their teacher. Individual teacher licenses are
          intended for single-teacher use. We reserve the right to reach out to
          end-users and/or revoke access in cases where it appears more than one
          teacher is using an account without prior consent.</p>
        </li>

        <li>
          <p><strong>School or District account (PLUS/INTEGRAL):</strong> A school
          or district account (either PLUS or INTEGRAL) is for a single school
          or district, all of the teachers within that school or district, and
          all of the students rostered to each teacher's classes (i.e. students
          that have this teacher as the teacher of record). School or district
          accounts may be purchased through the DeltaMath website or by email.
          School or district accounts are sold per school year and do not
          automatically renew. The school or district must take action to renew
          this account. See the “School Accounts and Student Data” section below
          for more information.</p>
          <p>
          Student registration will vary, depending on the integrations the
          teacher is using in their account, and students will complete
          assignments made by their teacher.
          </p>
        </li>

        <li>
          <p><strong>DeltaMath for Home Parent account:</strong> A DeltaMath for
          Home Parent account is intended for the parent or guardian of an
          associated learner. A parent or guardian who would like to sign their
          child up for a Learner account can create a free Parent account for
          DeltaMath for Home through the DeltaMath website. Within the account,
          the parent or guardian can purchase an associated Learner account(s)
          that can be used by the parent or guardian's child(ren). See the
          “Subscription and Billing for Accounts for DeltaMath for Home” section
          below for more information.</p>
        </li>

        <li>
          <p><strong>DeltaMath for Home Learner account:</strong> A DeltaMath for
          Home Learner account cannot be created directly by anyone under 13
          years of age. Learners under 13 years of age must be invited to create
          an account by a parent or guardian using a DeltaMath for Home Parent
          account. In this case, billing is associated with the Parent account.</p>
          <p>
            Learners 13 years of age or older can also be invited to create an
            account by a parent or guardian using a DeltaMath for Home Parent
            account. However, they may also create an independent Learner
            account. In the case where a learner has created their own DeltaMath
            for Home Learner account that is not associated with a Parent
            account, billing will be associated with the independent Learner
            account.
          </p>
          <p>
            Learner accounts are typically for a term of one month or one year
            from date of purchase and do automatically renew.{" "}
          </p>
          <p>
            In the case where a Parent account has one or more associated
            Learner accounts and then adds another Learner account, that Learner
            account would automatically join the Service and billing cycle of
            the currently existing accounts at a prorated cost until the next
            billing cycle.
          </p>
        </li>
      </ol>

      <h2>School Accounts and Student Data</h2>
      <p>This Section applies to a School's use of the Service.</p>
      <h2>Personal Information and Student Data</h2>
      <p>
        The U.S. Children's Online Privacy and Protection Act (“COPPA”) requires
        that online service providers obtain verifiable parental consent before
        collecting personal information from children under 13. If you are a
        School providing the Service to children under 13 (whether in the U.S.
        or elsewhere), you represent and warrant that you have received consent
        from parents, or have the authority to provide consent on behalf of
        parents, for us to collect information from students before allowing
        children under 13 to access DeltaMath Services. We recommend that all
        Schools provide appropriate disclosures to students and parents
        regarding their use of service providers such as DeltaMath.
      </p>
      <p>
        When DeltaMath is used by a School for an educational purpose, DeltaMath
        may collect or have access to Student Data that is provided by the
        School or by the Student. “Student Data” is personal information that is
        directly related to an identifiable Student and may include “educational
        records” as defined by the Family Educational Rights and Privacy Act
        (“FERPA”), 20 U.S.C. § 1232(g).
      </p>
      <p>
        <strong>Confidentiality.</strong> DeltaMath agrees to treat Student Data
        as confidential and not to share it with third parties other than as
        described in these Terms, and in our{" "}
        <Link to="/privacy-policy">Privacy Policy</Link>.
      </p>
      <p>
        <strong>Student Data Access.</strong> You authorize DeltaMath to access
        or collect Student Data for the purpose of providing the Service. In the
        U.S., DeltaMath shall collect and process Student Data as a School
        Official with a legitimate educational interest pursuant to FERPA 34 CFR
        Part 99.31(a)(1).
      </p>
      <p>
        <strong>
          Personal Information and Student Data Consents and Authority.
        </strong>{" "}
        If you are School User, you represent and warrant that you have provided
        appropriate disclosures to your School and to parents regarding your
        sharing such Personal Information with DeltaMath. Both Parties agree to
        uphold their obligations under the Family Educational Rights and Privacy
        Act (“FERPA”), the Protection of Pupil Rights Amendment ("PPRA"), and
        the Children's Online Privacy and Protection Act (“COPPA”) and
        applicable State laws relating to student data privacy. DeltaMath relies
        on each School to obtain and provide appropriate consent and
        disclosures, if necessary, for DeltaMath to collect any Student Data,
        including the collection of Student Data directly from students under
        13, as permitted under COPPA. You agree to comply with these Terms and
        all laws and regulations governing the protection of personal
        information, including children's information, and the sharing of
        student education records.
      </p>
      <p>
        <strong>Use of Student Data.</strong> By submitting or providing us
        access to Student Data, you agree that DeltaMath may use the Student
        Data solely for the purposes of (i) providing the Service, (ii)
        improving and developing our Service, (iii) enforcing our rights under
        these Terms, and (iv) as permitted with the School's or the User's
        consent. DeltaMath shall not use Student Data to engage in targeted
        advertising.
      </p>
      <p>
        <strong>Use of Anonymized Student Data.</strong> You agree that we may
        collect and use data derived from Student Data for our own purposes,
        such as for product development, research analytics, and marketing our
        Service, provided that such data will be de-identified and/or aggregated
        to reasonably avoid identification of a specific individual.
      </p>
      <p>
        <strong>Use of Personal Information for Marketing.</strong> You agree
        that DeltaMath may provide information about new DeltaMath features and
        offerings to school or district administrative users and teachers from
        time to time, provided that such advertisements shall not be based on
        Student Data. For emphasis, and without limitation, DeltaMath shall
        never use Student Data to engage in targeted advertising.
      </p>
      <p>
        <strong>Third-Party Service Providers.</strong> You acknowledge and
        agree that DeltaMath may provide access to Student Data to our employees
        and service providers, which have a legitimate need to access such
        information in order to provide their services to us. We and our
        employees, affiliates, service providers, or agents involved in the
        handling, transmittal, and processing of Student Data will be required
        to maintain the confidentiality of such data.
      </p>
      <p>
        <strong>Student Data Retention and Deletion Requests.</strong> DeltaMath
        retains Student Data, but for educational purposes only. Oftentimes
        students will want to refer back to their DeltaMath student accounts for
        college or other future courses. However, if a student does not utilize
        their account for a period of two years, their account and personally
        identifiable information will be deleted. Additionally, Schools may
        request that we delete Student Data in our possession at any time by
        providing such a request in writing. We shall respond to the deletion
        request as soon as possible, but in most instances within 45 days, other
        than for data stored on backup tapes which shall be deleted in the
        ordinary course of business. A Parent or student over the age of 18
        seeking to modify, correct, or delete personal information in a Student
        Account that is connected to an active School account will be instructed
        to contact the School to discuss data deletion or modification. We are
        not required to delete data that has been derived from Student Data so
        long as it has been anonymized such that it does not reasonably identify
        an individual.
      </p>
      <h2>Refund Policy</h2>
      <p>
        Refunds for site or individual teacher licenses are available within one
        month (30 days) of purchase. Full or partial refunds are not available
        after that time, and in no case will unused licenses roll over to the
        next school year. Contact orders@deltamath.com to request a refund.
      </p>
      <h2>Subscription and Billing for Accounts for DeltaMath for Home</h2>
      <p>
        This Section applies to accounts created through the Service using a
        credit card and that automatically renew.
      </p>
      <p>
        To view specific details regarding your subscription with DeltaMath for
        Home, sign in to your account and click on your name. If you have any
        questions about your subscription or billing, you may also contact
        home@deltamath.com directly for assistance.
      </p>
      <h2>Automatic Renewal and Payment Authorization</h2>
      <p>
        By starting your DeltaMath for Home subscription, you expressly
        authorize us to charge your payment method for the subscription fee
        associated with your chosen subscription term (e.g., monthly or yearly).
        Subscription renewal fees will be automatically charged to the card on
        file at the beginning of each subscription period (monthly or yearly),
        and will continue unless you cancel your subscription.
      </p>
      <p>
        Your subscription will automatically renew on the anniversary of your
        subscription date (either monthly or yearly), and we will charge your
        current payment method (or a new payment method if you update your
        account information) the applicable fee, along with any sales or similar
        taxes that may apply. Prices and charges are subject to change, but we
        will provide advance notice of any changes.
      </p>
      <p>
        As part of this Agreement, the term “billing” refers to any charge or
        debit made against your payment method.
      </p>
      <h2>Variation in Billing Amounts</h2>
      <p>
        You acknowledge that the billed amount may vary from period to period,
        due to factors such as changes in your subscription. By agreeing to this
        Agreement, you authorize us to charge your payment method for varying
        amounts as necessary. While we may change the fees and charges from time
        to time, including adding new fees, we will provide advance notice of
        any such changes.
      </p>
      <h2>Managing Your Payment Method</h2>
      <p>
        If you wish to update your payment method, or if your payment
        information changes (e.g., credit card expiration or validity), you can
        edit your payment details on your account page. To access this page,
        sign in to your DeltaMath for Home account, click on your name, and
        select "Update Payment." If your payment method expires and you do not
        update your payment information or cancel your subscription, you
        authorize the Service to retry charging the card up to four times in one
        week. If the card still does not go through, we will cancel your
        subscription.
      </p>
      <h2>Cancellation of Subscription</h2>
      <p>
        To avoid future charges, you must cancel your subscription before the
        renewal date of each billing period. The subscription fee will be
        charged at the start of each period (monthly or yearly) and will
        continue unless you cancel. To view the commencement date of your next
        renewal, sign in to your DeltaMath for Home account and click on your
        name.
      </p>

      <p>
        If your monthly subscription begins on a day that is not part of the
        calendar month (e.g., January 31), we will bill your payment method on
        the last day of that month (e.g., February 28).
      </p>
      <h2>Canceling Automatic Renewals</h2>
      <p>
        You can cancel your subscription at any time. Cancellation will be
        effective immediately, and you will retain access to the Service until
        the current billing period ends. Please note that we do not provide
        refunds or credits for any partially used subscription periods. To
        cancel your subscription, sign in to your DeltaMath for Home account,
        click your name, and then click “Cancel Subscription.”
      </p>
      <h2>Refund Policy for DeltaMath for Home</h2>
      <p>We offer a 7-day free trial. We do not offer refunds.</p>
      <h2>Price Changes</h2>
      <p>
        We reserve the right to adjust the pricing for our Service, including
        but not limited to subscription plans, in any manner and at any time as
        we may determine in our sole and absolute discretion. Except as
        otherwise expressly provided for in this Agreement, any price changes to
        DeltaMath for Home will take effect following posting or other notice to
        you (e.g., e-mail).
      </p>
      <h2>Use License</h2>
      <p>
        The Service and the DeltaMath Technology are intended solely for the
        personal, non-commercial use of our users and may only be used in
        accordance with this Agreement. “DeltaMath Technology” means all past,
        present and future content of the Service, including, all the software,
        hardware and technology used to provide the Service (including DeltaMath
        proprietary code and third-party software), user interfaces, materials
        displayed or performed on the Service, such as text, graphics, articles,
        graphs, photographs, images, illustrations and the design, structure,
        sequence and “look and feel” of the Services, and all other intellectual
        property. DeltaMath Technology is protected by copyright and other
        intellectual property laws. You are not allowed to use, store, copy,
        reproduce, modify, translate, publish, broadcast, transmit, distribute,
        perform, upload, create derivative works from, display, license, sell or
        otherwise exploit the DeltaMath Technology for any purposes other than
        as expressly permitted under this Agreement. You will not decompile,
        reverse engineer, or otherwise attempt to obtain the source code of any
        DeltaMath Technology.
      </p>
      <p>
        Using our Service does not give you ownership of any intellectual
        property rights in our Service or the DeltaMath Technology. You may not
        use content from our Services, unless you obtain permission from its
        owner or are otherwise permitted by law. Don't remove, obscure, or alter
        any copyright or other legal notices displayed in or along with our
        Services.
      </p>
      <p>
        By using the Services, you agree not to do any of these things: (i)
        copy, distribute, or disclose any part of the Services in any medium,
        including but not limited to by any automated or non-automated
        “scraping”; (ii) use any automated system, including but not limited to
        “robots,” “spiders,” “offline readers,” etc., to access the Services in
        a manner that sends more request messages to the DeltaMath servers than
        a human can reasonably produce in the same period of time by using a
        conventional online web browser; (iii) transmit spam, chain letters, or
        other unsolicited email; (iv) attempt to interfere with, compromise the
        system integrity or security or decipher any transmissions to or from
        the servers running the Services; (v) take any action that imposes, or
        may impose an unreasonable or disproportionately large load on our
        infrastructure; (vi) transmit any malicious software agents through the
        Services; (vii) collect or harvest any third-party personally
        identifiable information, including account names or Student Data (as
        defined above), from the Services; (viii) use the Services for any
        commercial solicitation purposes; (ix) impersonate another person or
        otherwise misrepresenting your affiliation with a person or entity,
        conducting fraud, hiding or attempting to hide your identity; (x)
        interfere with the proper working of the Services; (xi) access any
        content on the Services through any technology or means other than those
        provided or authorized by the Services; or (xii) bypass the measures we
        use to prevent or restrict access to the Services, including but not
        limited to features that prevent or restrict use or copying of any
        content or enforce limitations on use of the Services or related
        content.
      </p>
      <h2>Privacy</h2>
      <p>
        Your privacy is extremely important to us, and we take great care to
        limit what we collect and how we use it, and to enable you to understand
        our policies. Please read our{" "}
        <Link to="/privacy-policy">Privacy Policy</Link> which explains how we
        treat your personal information and protect your privacy when you use
        our Service. By using our Service, you agree that DeltaMath can use such
        data in accordance with our{" "}
        <Link to="/privacy-policy">Privacy Policy</Link> or{" "}
        <Link to="/childrens-privacy-policy">Children's Privacy Policy</Link>,
        depending on the account type.
      </p>
      <h2>Passwords and Security</h2>
      <p>
        You are responsible for maintaining the confidentiality of your
        DeltaMath password, and you are solely responsible for all activities
        that occur under your password. You agree to immediately notify
        DeltaMath of any unauthorized use of your password or any other breach
        of security related to the DeltaMath Services. DeltaMath may require you
        to alter your password if we believe that your password is no longer
        secure.
      </p>
      <h2>Links</h2>
      <p>
        DeltaMath has not reviewed all of the sites linked to its website and is
        not responsible for the contents of any such linked site. The inclusion
        of any link does not imply endorsement by DeltaMath of the site. Use of
        any such linked website is at the user's own risk.
      </p>
      <h2>Modifications</h2>
      <p>
        DeltaMath may revise these terms of service for its website at any time
        without notice. By using this website you are agreeing to be bound by
        the then current version of these terms of service.
      </p>
      <h2>Disclaimer and Limitations of Liability</h2>
      <p>
        a. You agree that use of the DeltaMath services is at your sole risk.
        The DeltaMath services are provided on an “as is” and “as available”
        basis. DeltaMath expressly disclaims all warranties of any kind, whether
        express or implied, with respect to the DeltaMath services, including,
        but not limited to, the implied warranties of merchantability, fitness
        for a particular use or purpose, and non-infringement. You acknowledge
        that access to data and materials available through the DeltaMath
        services is not guaranteed and that DeltaMath will not be responsible to
        you for any loss of data or materials caused by the DeltaMath services
        or their unavailability. You understand and agree that any data,
        materials, services and/or information downloaded or otherwise obtained
        through the use of the DeltaMath services is done at your own discretion
        and risk and that you will be solely responsible for any damage arising
        therefrom.
      </p>
      <p>
        b. Under no circumstances will DeltaMath or its officers, employees,
        directors, shareholders, agents, or licensors be liable under any theory
        of liability (whether in contract, tort, statutory, or otherwise) for
        any damages whatsoever, including direct, indirect, incidental, special,
        consequential or exemplary damages, including but not limited to,
        damages for loss of money, revenues, profits, goodwill, use, data or
        other intangible losses (even if such parties were advised of, knew of
        or should have known of the possibility of such damages), resulting from
        your (or anyone using your account's) use of the DeltaMath services.
      </p>

      <p>
        c. If, notwithstanding these Terms, DeltaMath is found to be liable to
        you or any third party in connection with your use of the DeltaMath
        services, the total liability of DeltaMath and its officers, employees,
        directors, shareholders, agents, or licensors to you or to any third
        party is limited to one hundred U.S. Dollars ($100).
      </p>
      <p>
        d. Exclusions And Limitations. Some jurisdictions do not allow the
        exclusion of certain warranties or the limitation or exclusion of
        liability for certain damages. Accordingly, some of the above
        limitations and disclaimers may not apply to you. To the extent that
        DeltaMath may not, as a matter of applicable law, disclaim any implied
        warranty or limit its liabilities, the scope and duration of such
        warranty and the extent of DeltaMath's liability will be the minimum
        permitted under such applicable law.
      </p>
      <h2>Indemnification</h2>
      <p>
        You agree to indemnify, defend, and hold harmless DeltaMath and its
        officers, directors, employees, consultants and agents from and against
        any and all claims, liabilities, damages, losses, costs, expenses, fees
        (including reasonable attorneys' fees) that such parties may incur as a
        result of or arising from your (or anyone using your account's)
        violation of these Terms. DeltaMath reserves the right, at its own
        expense, to assume the exclusive defense and control of any matter
        otherwise subject to indemnification by you, and in such case, you agree
        to cooperate with DeltaMath's defense of such claim.
      </p>
      <h2>Termination</h2>
      <p>
        This Agreement shall remain in full force and effect while you use the
        Service. DeltaMath may suspend or terminate your access to the Service
        or your account at any time, for any reason (without cause or for your
        violation of any term of this Agreement), and without warning or notice,
        which may result in the loss of information associated with your
        account. Upon termination of your account, your right to use the Service
        will immediately cease. All provisions of this Agreement which, by their
        nature, should survive termination, shall survive termination,
        including, without limitation, ownership provisions, warranty
        disclaimers, and limitations of liability.
      </p>
      <p>
        DeltaMath can modify, suspend, or temporarily discontinue the Service at
        any time, with or without notice, and is not liable to users or third
        parties for any such changes. If the Service is permanently
        discontinued, DeltaMath's liability is limited to a refund of the
        prorated subscription price based on the time remaining on the
        subscription.
      </p>
      <h2>Miscellaneous</h2>
      <p>
        The failure of either party to exercise, in any respect, any right
        provided for herein shall not be deemed a waiver of any further rights
        hereunder. DeltaMath shall not be liable for any failure to perform its
        obligations hereunder where such failure results from any cause beyond
        DeltaMath's reasonable control. If any provision of this Agreement is
        found to be unenforceable or invalid, that provision shall be limited or
        eliminated to the minimum extent necessary so that this Agreement shall
        otherwise remain in full force and effect and enforceable. This
        Agreement is not assignable, transferable or sublicensable by you except
        with DeltaMath's prior written consent. DeltaMath may transfer, assign
        or delegate this Agreement and its rights and obligations without
        restriction. This Agreement is governed by and construed in accordance
        with the laws of the State of New York without regard to the conflict of
        laws provisions thereof. To the extent any dispute arising from or
        relating to the subject matter of this Agreement is permitted to be
        brought in a court of law, such claim shall be subject to the exclusive
        jurisdiction of the state and federal courts located in New York County,
        New York, and for all purposes of this Agreement, you and DeltaMath
        consent to the exclusive jurisdiction and venue of such courts. Unless
        and solely to the extent that you or your Institution have a separate
        written agreement with DeltaMath that governs your use of the Service
        (in which case such agreement will control), we both agree that this
        Agreement is the complete and exclusive statement of the mutual
        understanding of the parties and supersedes and cancels all previous
        written and oral agreements, communications and other understandings
        relating to the subject matter of this Agreement, and that the Agreement
        may not be modified, except as otherwise provided herein. This Agreement
        and any subsequent versions of this Agreement posted to the Website will
        be deemed a writing signed by both parties. No agency, partnership,
        joint venture, or employment is created as a result of this Agreement
        and you do not have any authority of any kind to bind DeltaMath in any
        respect whatsoever.
      </p>
      <h2>User Content</h2>
      <p>
        While using the Service, you are fully responsible for any content that
        you create, transmit, or display.
      </p>
      <p>
        Currently, or in the future, the Service may allow Users to submit,
        post, display, or otherwise share content, including but not limited to
        text, images, comments, questions, and other forms of information. This
        content, referred to as "User Content," is made available through the
        Service by Users.
      </p>
      <p>
        You retain full ownership of your User Content, and we do not assert any
        rights over it.
      </p>
      <p>
        When you submit, post, display, provide, or otherwise make available any
        User Content through the Service or to DeltaMath, you grant DeltaMath a
        royalty-free, sublicensable, transferable, perpetual, irrevocable,
        non-exclusive, worldwide license to use, reproduce, modify, publish,
        edit, translate, distribute, syndicate, publicly perform, publicly
        display, and create derivative works from that User Content, in whole or
        in part, in any form, media, or technology—whether existing now or
        developed in the future. This license allows DeltaMath to utilize your
        User Content in connection with the Service and its business, including
        for promotional purposes and redistribution in various media formats and
        channels. Additionally, you provide each User of the Service with a
        non-exclusive license to access your User Content and to use, reproduce,
        distribute, display, and perform it as permitted by the functionality of
        the Service and under this Agreement.
      </p>
      <p>
        You must ensure that you have the legal rights to any User Content you
        submit. You are not permitted to upload or post any content that
        infringes upon the copyright, trademark, or other intellectual property
        rights of third parties. Likewise, you may not upload User Content that
        violates any third party's right to privacy or publicity. You may only
        submit User Content that you have the permission to post from the owner
        or as permitted by law.
      </p>
      <h2>International Use</h2>
      <p>
        Given the global nature of the Internet, you agree to adhere to all
        local laws, regulations, and rules applicable to the jurisdiction(s) in
        which you reside. You are solely responsible for ensuring compliance
        with these legal requirements. Additionally, you agree to follow all
        applicable laws governing the transmission of data exported from the
        United States or from your jurisdiction(s).
      </p>
      <h2>No Resale of Service</h2>
      <p>
        You agree not to reproduce, duplicate, copy, sell, resell, or exploit
        any part of the Service, including your use of or access to it, for any
        commercial purposes.
      </p>
      <h2>Arbitration</h2>
      <p>
        Please read this section carefully, as it requires both parties to
        resolve disputes through arbitration and limits the ways you can seek
        relief from DeltaMath. If you have a dispute with DeltaMath, you agree
        to first reach out to us at support@deltamath.com to attempt an informal
        resolution. If, after sixty (60) days, the dispute remains unresolved,
        both parties agree to settle any claims, disputes, or controversies
        (collectively referred to as "Claims") arising from or related to this
        agreement, including any alleged breaches, through binding arbitration
        conducted by AAA. This arbitration will follow the Expedited Procedures
        in effect for AAA, unless stated otherwise in this agreement. You can
        contact AAA at <a href="https://www.adr.org/">https://www.adr.org/</a>.
        The arbitration will take place in New York, NY, unless you and
        DeltaMath agree on a different location.
      </p>
      <p>
        If you are a School or are using the Service for commercial purposes,
        both parties are responsible for paying any filing, administrative, and
        arbitrator fees according to AAA rules. The arbitrator's award will
        include the costs of arbitration, reasonable attorneys' fees, and
        reasonable costs for expert witnesses and other witnesses.
      </p>
      <p>
        If you are an individual using the Service for non-commercial purposes:
        (i) you may be required to pay an initiation fee to AAA unless you
        successfully obtain a fee waiver; (ii) the arbitrator's award may cover
        your arbitration costs, reasonable attorney's fees, and reasonable costs
        for expert witnesses and others; and (iii) you may file a lawsuit in a
        small claims court of competent jurisdiction without first going through
        arbitration, although this does not waive your obligation to attempt
        informal dispute resolution.
      </p>
      <p>
        Any judgment resulting from the arbitrator's award may be enforced in
        any court of competent jurisdiction. Furthermore, this section does not
        prevent DeltaMath from seeking injunctive or other equitable relief in
        court to protect against actual or threatened infringement,
        misappropriation, or violation of our data security, intellectual
        property rights, or other proprietary rights.
      </p>
      <h2>Class Action/Jury Trial Waiver</h2>
      <p>
        All claims must be brought individually by all persons and entities,
        regardless of whether they have used the Service for personal,
        commercial, or other purposes. No claims may be asserted as a plaintiff
        or class member in any purported class action, collective action,
        private attorney general action, or other representative proceeding.
        This waiver extends to class arbitration, and unless otherwise agreed
        upon, the arbitrator is not permitted to consolidate claims from
        multiple individuals.
      </p>
      <p>
        By entering into this agreement, both you and DeltaMath acknowledge that
        you are waiving your right to a jury trial and your right to participate
        in any class action, collective action, private attorney general action,
        or other representative proceeding.
      </p>
    </div>
  );
}
