import React from "react";

import Layout from "../components/Layout/Layout";
import Terms from "../components/TermsOfService/Terms.js";
import Hero from "../components/TermsOfService/Hero.js";

import "../styles/pages/termsOfService.scss";

export default function TermsOfService(props) {
  return (
    <Layout>
      <Hero />
      <Terms />
    </Layout>
  );
}
